import React, { useState } from 'react';
import Antipublicista from './components/Antipublicista';
import './App.css';

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

const App = () => {
  return (
    <div className="app-container">
      <h1>En desarrollo</h1>
      <Antipublicista />
    </div>
  );
};

export default App;
