import React from 'react';

const Antipublicista = () => {
  // Ruta de la imagen
  const imageUrl = process.env.PUBLIC_URL + '/logo.png';

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <img src={imageUrl} alt="Example" style={{ width: '7vw' }} />
    </div>
  );
};

export default Antipublicista;
